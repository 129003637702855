<!-- vue coding=utf-8 -->
<!--
 * @Author: Chen-
 * @Description: 查看物流
 * @Date: 2021-01-04 18:45:37
 * @LastEditors: OBKoro1
 * @LastEditTime: 2021-01-20 20:45:38
 * @FilePath: /yue_quanzhan_h5_new/src/views/user/order/logistics.vue
-->
<template>
  <div class="user_content">
    <div class="spcenter">
      <div><img :src="goodCover" alt=""></div>
      <div class="spcenter_text">
        <div>{{ goodName }}</div>
        <div>
          <span>数量:{{ goodNum }} </span>
          <span>规格:{{ goodSpec }} </span>
        </div>
        <div>¥{{ payPrice }}元</div>
      </div>
    </div>
    <div class="logis_no">
      <div>物流单号：<span id="ordernumber">{{ logistice_number }}</span> <span class="copy" @click="copy">复制</span></div>
      <div>物流公司：{{ logistice_company }}</div>
    </div>
    <div class="log">
      <van-empty v-if="!logisticeInfo.length" description="暂无物流信息" />
      <ul>
        <li v-for="(item,index) in logisticeInfo" :key="index" class="log_li">
          <p :style="{color:(index == 0?'#000':'')}">{{ item.context }}</p>
          <p :style="{color:(index == 0?'#000':'')}">{{ item.status }}</p>
          <p :style="{color:(index == 0?'#000':'')}">{{ item.time }}</p>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { Empty, Toast } from 'vant'

Vue.use(Empty)
Vue.use(Toast)
import {
  goodsExpress
} from '@/services/userApi'
import { copyText } from '@/utils/common/utils'

export default {
  data() {
    return {
      recordId: '',
      goodName: '',
      goodSpec: '',
      logistice_company: '',
      logistice_number: '',
      goodCover: '',
      logisticeInfo: []
    }
  },
  created() {
    this.recordId = this.$route.query.recordId
    this.goodCover = this.$route.query.goodCover
    this.goodName = this.$route.query.goodName
    this.payPrice = this.$route.query.payPrice
    this.goodNum = this.$route.query.goodNum
    this.goodSpec = this.$route.query.goodSpec
    this.getinfo()
  },
  methods: {
    getinfo() {
      const prom = {
        recordId: this.recordId
      }
      goodsExpress(prom).then(res => {
        if (Number(res.code) === 200) {
          this.logistice_company = res.list.logistice_company
          this.logistice_number = res.list.logistice_number
          this.logisticeInfo = res.list.logisticeInfo
        }
      })
    },
    copy() {
      copyText(this.logistice_number, () => {
        Toast('复制成功')
      })
    }
  }
}
</script>

<style lang="less" scoped>
.user_content {
  width: 100%;

  box-sizing: border-box;
  height: auto;
  // overflow: auto;
  position: fixed;
  top: -3px;
  left: 0;
  right: 0;
  bottom: 0;
  background: #f6f6f6;
  // padding-bottom: 80px;
  .copy {
    padding: 3px 6px;
    box-sizing: border-box;
    border-radius: 10px;
    font-size: 10px;
    margin-left: 10px;
    border: 1px solid #999;
  }

  .spcenter {
    // margin: 10px 0 0;
    padding: 13px;
    background: #fff;
    display: flex;

    & > div:nth-child(1) {
      img {
        max-width: 74px;
        max-height: 74px;
        border-radius: 3px;
      }
    }

    .spcenter_text {
      width: 80%;
      margin: 5px 10px;

      & > div:nth-child(1) {
        color: #333333;
        text-align: left;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
      }

      & > div:nth-child(2) {
        text-align: left;
        margin-top: 10px;
        color: #333333;
        line-height: 20px;
      }

      & > div:nth-child(3) {
        text-align: left;
        margin-top: 10px;
        color: #E33F44;
      }
    }
  }

  .logis_no {
    background: #fff;
    padding: 0 13px 13px;
    text-align: left;
    line-height: 20px;
  }

  .log {
    margin-top: 10px;
    background: #fff;

    & > ul {
      height: calc(100vh - 175px);
      overflow: scroll;
    }

    .log_li {
      padding: 13px;
      text-align: left;
      border-bottom: 1px solid #eee;

      & > p {
        line-height: 20px;
        color: #999;
        font-size: 12px;
      }
    }
  }
}
</style>

